/*-----------------------------------------------
|   Notice
-----------------------------------------------*/
.notice{
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0;
  z-index: -1;
  width: 530px;
  max-width: 100%;
  border-radius: 0;
  &.show{ z-index: 1020; }
}
.notice-right{
  left: auto;
  right: 0;
}
