/*-----------------------------------------------
|   Card
-----------------------------------------------*/

.card-body:last-child {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}


/*-----------------------------------------------
|   Card spacer
-----------------------------------------------*/
.ps-card,
.px-card,
.p-card {
  padding-left: $card-spacer-x !important;
}
.pe-card,
.px-card,
.p-card {
  padding-right: $card-spacer-x !important;
}
.pt-card,
.py-card,
.p-card {
  padding-top: $card-spacer-x !important;
}
.pb-card,
.py-card,
.p-card {
  padding-bottom: $card-spacer-x !important;
}
.mt-card {
  margin-top: $card-spacer-x !important;
}
.mr-card {
  margin-right: $card-spacer-x !important;
}

.ms-ncard,
.mx-ncard,
.m-ncard {
  margin-left: -$card-spacer-x !important;
}
.me-ncard,
.mx-ncard,
.m-ncard {
  margin-right: -$card-spacer-x !important;
}